<template>
  <div>
    <!-- NOTE: 商品選択 start -->
    <v-select
      v-if="!isNonExistVendible"
      :value="{ vendibleId: vendibleId, vendibleType: vendibleType }"
      :items="vendibleItems"
      :item-value="item => {
        if (item.attributes) return { vendibleId: item.id, vendibleType: item.attributes.vendibleType }
      }"
      :value-comparator="(a, b) => {
        if (a && b) return +a.vendibleId === +b.vendibleId && a.vendibleType === b.vendibleType
      }"
      item-text="attributes.name"
      :error="!vendibleId"
      :append-icon="icons.mdiSelection"
      hide-details
      hide-selected
      no-data-text="選択可能なデータがありません"
      :menu-props="{ auto: true, offsetY: true, contentClass: 'vendible-select-menu' }"
      class="vendible-select"
      @change="onSelect($event)"
    >
      <template #selection="{ item: vendible }">
        <v-avatar
          v-if="vendible.attributes.color"
          size="16"
          :color="vendible.attributes.color"
          left
          class="mr-2"
        >
        </v-avatar>
        {{ vendible.attributes.name }}
      </template>

      <template #item="{ item: vendibleItem }">
        <template v-if="!vendibleItem.attributes">
          <!-- eslint-disable-next-line vue/no-v-text-v-html-on-component -->
          <v-list-item-content v-text="vendibleItem.header" />
        </template>

        <template v-else>
          <v-list-item-avatar
            v-if="vendibleItem.attributes.color"
            size="20"
            :color="vendibleItem.attributes.color"
          >
          </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title>
              {{ vendibleItem.attributes.name }}
              <small class="ml-2 text--secondary">
                {{ `¥${vendibleItem.attributes.sellingPrice.toLocaleString()}` }}
              </small>
            </v-list-item-title>
          </v-list-item-content>
        </template>
      </template>

      <!-- NOTE: 販売時点不存在商品作成ボタン start -->
      <template
        v-if="isNonExistAllowable"
        #append-outer
      >
        <v-btn
          icon
          small
          class="ma-0 pa-0"
          :ripple="false"
          @click="toggleIsNonExistVendible"
        >
          <v-icon>
            {{ icons.mdiEmoticonCoolOutline }}
          </v-icon>
        </v-btn>
      </template>
      <!-- NOTE: 販売時点不存在商品作成ボタン end -->
    </v-select>
    <!-- NOTE: 商品選択 end -->

    <!-- NOTE: 販売時点不存在商品命名フィールド start -->
    <v-text-field
      v-if="isNonExistAllowable && isNonExistVendible"
      :value="vendibleName"
      :error="!vendibleName"
      label="名前"
      hide-details
      single-line
      class="pa-0"
      @input="onName($event)"
    >
      <!-- NOTE: 通常商品選択/販売時点不存在商品作成トグル start -->
      <template #append-outer>
        <v-btn
          icon
          small
          color="primary"
          class="ma-0 pa-0"
          :ripple="false"
          @click="toggleIsNonExistVendible"
        >
          <v-icon>
            {{ icons.mdiEmoticonCoolOutline }}
          </v-icon>
        </v-btn>
      </template>
      <!-- NOTE: 通常商品選択/販売時点不存在商品作成トグル end -->
    </v-text-field>
    <!-- NOTE: 販売時点不存在商品命名フィールド end -->

    <!-- NOTE: 単価 start -->
    <div
      :key="`selling-price-${isChangingSellingPrice}`"
      class="d-flex align-center justify-start pt-2 selling-price-container"
    >
      <v-card-subtitle
        v-if="!isChangingSellingPrice"
        class="ma-0 pa-0"
      >
        <span v-if="vendibleId && vendibleType">
          <span class="text--disabled mr-2">単価:</span>
          {{ `¥${pickVendible(vendibleId, vendibleType).attributes.sellingPrice.toLocaleString()}` }}

          <!-- NOTE: 任意の単価入力切り替え start -->
          <v-btn
            icon
            small
            right
            :ripple="false"
            class="selling-price-edit-btn"
            @click="isChangingSellingPrice = true"
          >
            <v-icon>
              {{ icons.mdiPencilOutline }}
            </v-icon>
          </v-btn>
          <!-- NOTE: 任意の単価入力切り替え end -->
        </span>
      </v-card-subtitle>

      <!-- NOTE: 任意の単価入力 start -->
      <div
        v-else
        class="d-flex align-center justify-start flex-wrap"
      >
        <span class="text--disabled text-sm mr-2">単価:</span>
        <v-text-field
          :value="sellingPrice"
          :error="!(sellingPrice === 0 || sellingPrice)"
          type="number"
          inputmode="numeric"
          pattern="[0-9]*"
          min="0"
          prefix="¥"
          hide-details
          class="pa-0 ma-0"
          @input="onChangeSellingPrice($event)"
        >
          <template #append>
            <v-icon
              v-if="!isNonExistVendible"
              small
              @click="resetSellingPrice"
            >
              {{ icons.mdiPencilOffOutline }}
            </v-icon>
          </template>

          <template #append-outer>
            <v-btn
              text
              small
              :ripple="false"
              @click="makeSellingPriceNegative"
            >
              × -1
            </v-btn>
          </template>
        </v-text-field>
      </div>
      <!-- NOTE: 任意の単価入力 end -->
    </div>
    <!-- NOTE: 単価 end -->
  </div>
</template>

<script>
import { ref, computed, getCurrentInstance } from '@vue/composition-api'
import { useVModels } from '@vueuse/core'
import {
  mdiSelection,
  mdiEmoticonCoolOutline,
  mdiPencilOutline,
  mdiPencilOffOutline,
} from '@mdi/js'
import {
  each,
  groupBy,
  find,
  filter,
} from 'lodash'
import useCurrentData from '@/views/composable/useCurrentData'

export default {
  props: {
    vendibles: {
      type: Array,
      required: true,
      default: () => [],
    },
    isNonExistVendible: {
      type: Boolean,
      default: false,
    },
    vendibleId: {
      type: [Number, String],
      default: null,
    },
    vendibleType: {
      type: String,
      default: null,
    },
    vendibleName: {
      type: String,
      default: null,
    },
    sellingPrice: {
      type: Number,
      default: null,
    },
    isEdit: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const vm = getCurrentInstance().proxy

    const { currentClub } = useCurrentData()
    const {
      isNonExistVendible,
      vendibleId,
      vendibleType,
      vendibleName,
      // NOTE:
      // vending_historyのbefore_validationで紐づく商品(vendible)の価格は自動セットされるので、
      // 任意の単価入力しないかぎり通常はnullでよい
      // しかしながら、概算の算出がvendingHistoriesTempのsellingPriceを見に行っているので、
      // vendibleと紐づく場合でもsellingPriceを基本的にはセットしなければならない
      sellingPrice,
    } = useVModels(props, emit)
    const isNonExistAllowable = ref(!(props.vendibleId || props.vendibleType)) // NOTE: どちらかが元々あれば販売時点不存在商品の作成を不許可に
    const isChangingSellingPrice = ref(true)

    const vendibleItems = computed(() => {
      const lists = []
      const typeOrder = ['Course', 'Nomination', 'Item']

      let items = [...props.vendibles]
      if (props.isEdit) {
        items = filter(items, o => o.attributes.vendibleType === vendibleType.value)
      }
      const vendiblesGrouped = groupBy(items, 'attributes.vendibleType')

      each(typeOrder, type => {
        if (!vendiblesGrouped[type]) return

        lists.push({
          header: {
            Course: currentClub.value.courseAlias,
            Nomination: currentClub.value.nominationAlias,
            Item: 'アイテム',
          }[type],
        })
        lists.push(...vendiblesGrouped[type])
      })

      return lists
    })

    const pickVendible = (targetVendibleId, targetVendibleType) => {
      return find(
        props.vendibles,
        o => +o.id === +targetVendibleId && o.attributes.vendibleType === targetVendibleType,
      )
    }

    const onSelect = e => {
      if (props.isEdit) {
        if (e.vendibleType === 'Course' && vendibleId.value !== e.vendibleId) {
          vm.$toast.warning('現在進行しているセットの時間は変更しません。時間が変わる場合は手動でご変更ください。')
        }
      }

      vendibleId.value = e.vendibleId
      vendibleType.value = e.vendibleType
      vendibleName.value = null
      sellingPrice.value = pickVendible(e.vendibleId, e.vendibleType)?.attributes?.sellingPrice
      isChangingSellingPrice.value = false
    }

    const onName = e => {
      vendibleName.value = e
    }

    const onChangeSellingPrice = e => {
      sellingPrice.value = +e
    }

    const resetSellingPrice = () => {
      isChangingSellingPrice.value = false
      sellingPrice.value = pickVendible(vendibleId.value, vendibleType.value)?.attributes?.sellingPrice
    }

    const toggleIsNonExistVendible = () => {
      if (!isNonExistAllowable.value) return

      isNonExistVendible.value = !isNonExistVendible.value
      isChangingSellingPrice.value = !isNonExistVendible.value // NOTE: 販売時点不存在商品であれば任意の単価を入力しなければいけないので
      vendibleName.value = null
      vendibleId.value = null
      vendibleType.value = null
      sellingPrice.value = null

      emit('toggle-is-non-exist', !isNonExistVendible.value)
    }

    const makeSellingPriceNegative = () => {
      sellingPrice.value *= -1
    }

    return {
      // data
      isNonExistAllowable,
      isChangingSellingPrice,

      // computed
      vendibleItems,

      // methods
      pickVendible,
      onSelect,
      onName,
      toggleIsNonExistVendible,
      onChangeSellingPrice,
      resetSellingPrice,
      makeSellingPriceNegative,

      icons: {
        mdiSelection,
        mdiEmoticonCoolOutline,
        mdiPencilOutline,
        mdiPencilOffOutline,
      },
    }
  },
}
</script>
