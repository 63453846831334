<template>
  <div>
    <v-select
      :value="vendibleRecipientsAttributes"
      :items="userItems"
      :item-value="item => {
        if (item.type === 'user') return item.id
        return item.userId
      }"
      :value-comparator="(a, b) => {
        if (!(+a > 0 && +b > 0)) return false
        return +a === +b
      }"
      item-text="attributes.name"
      :error="error"
      placeholder="ゲスト"
      chips
      disable-lookup
      hide-details
      multiple
      single-line
      small-chips
      hide-selected
      no-data-text="選択可能なデータがありません"
      :menu-props="{ auto: true, offsetY: true, contentClass: 'multiple-receiver-select-menu' }"
      class="multiple-receiver-select"
      @change="addVendibleRecipients($event)"
      @input="()=>{}"
    >
      <template #selection="{ item: user }">
        <v-menu
          v-model="onUserRatios[user.id]"
          :close-on-content-click="false"
          transition="scale-transition"
          bottom
          offset-y
        >
          <template #activator="{ on }">
            <v-badge
              dot
              left
              overlap
              avatar
              offset-x="12"
              offset-y="12"
              :color="badgeColor(user)"
            >
              <v-chip
                v-if="!(pickVendibleRecipient(user.id)._destroy)"
                close
                pill
                class="vendible-recipient-chip"
                v-on.prevent="on"
                @click:close="removeVendibleRecipient(user)"
              >
                <v-avatar left>
                  <v-img
                    v-if="user.attributes.profileImage"
                    :src="user.attributes.profileImage"
                  />
                  <v-icon v-else>
                    {{ icons.mdiAccountCircleOutline }}
                  </v-icon>
                </v-avatar>

                {{ user.attributes.name }}

                <template v-if="vendibleRecipientsAttributes.length > 1 || vendibleRecipientsAttributes.some(o => +o.ratio !== 1.0)">
                  (
                  <span
                    class="text-truncate"
                    style="max-width: 48px"
                  >
                    {{ Math.round(pickVendibleRecipient(user.id).ratio * 100) }}
                  </span>
                  %)
                </template>
              </v-chip>
            </v-badge>
          </template>

          <v-list>
            <v-list-item>
              <v-text-field
                type="number"
                inputmode="numeric"
                pattern="[0-9]*"
                :value="Math.round(pickVendibleRecipient(user.id).ratio * 100)"
                min="0"
                suffix="%"
                hide-details
                class="py-0 vendible-receive-ratio-input"
                @input="changeVendibleRecipientRatio($event, user)"
              />

              <v-list-item-action>
                <v-btn
                  icon
                  x-small
                  :ripple="false"
                  class="vendible-receive-ratio-check-btn"
                  @click="onUserRatios[user.id] = false"
                >
                  <v-icon>{{ icons.mdiCheck }}</v-icon>
                </v-btn>
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>

      <template #item="{ item: userItem, attrs }">
        <template v-if="!userItem.attributes">
          <!-- eslint-disable-next-line vue/no-v-text-v-html-on-component -->
          <v-list-item-content v-text="userItem.header" />
        </template>

        <template v-else>
          <v-list-item-action>
            <v-checkbox
              v-model="attrs.inputValue"
              hide-details
              :ripple="false"
            />
          </v-list-item-action>

          <v-badge
            dot
            left
            overlap
            avatar
            offset-x="24"
            offset-y="16"
            :color="badgeColor(userItem)"
          >
            <v-list-item-avatar
              size="32"
              class="mr-2"
            >
              <v-img
                v-if="userItem.attributes.profileImage"
                :src="userItem.attributes.profileImage"
              />
              <v-icon v-else>
                {{ icons.mdiAccountCircleOutline }}
              </v-icon>
            </v-list-item-avatar>
          </v-badge>

          <v-list-item-content>
            <v-list-item-title>
              {{ userItem.attributes.name }}
            </v-list-item-title>
          </v-list-item-content>
        </template>
      </template>
    </v-select>
  </div>
</template>

<script>
import { reactive, computed } from '@vue/composition-api'
import { useVModel } from '@vueuse/core'
import {
  chain,
  each,
  groupBy,
  find,
  keys,
  findIndex,
  update,
  map,
  xor,
  isEmpty,
} from 'lodash'
import {
  mdiHeartOutline,
  mdiCheck,
  mdiAccountCircleOutline,
} from '@mdi/js'

export default {
  props: {
    value: {
      type: Array,
      required: true,
      default: () => [],
    },
    users: {
      type: Array,
      required: true,
      default: () => [],
    },
    error: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const vendibleRecipientsAttributes = useVModel(props, 'value')
    const onUserRatios = reactive(chain(props.users).keyBy('id').mapValues(() => false).value())

    const userItems = computed(() => {
      const roleMsg = {
        cast: 'キャスト',
        waiter: 'ボーイ',
      }
      const lists = []
      const usersGrouped = groupBy(props.users, 'attributes.role')

      each(keys(roleMsg), role => {
        if (!usersGrouped[role]) return

        lists.push({ header: roleMsg[role] })
        lists.push(...usersGrouped[role])
      })

      return lists
    })

    const badgeColor = computed(() => userItem => {
      const { timecard } = userItem
      if (!timecard) return 'transparent'

      return timecard.leaveAt ? 'secondary' : 'success'
    })

    const pickUser = id => {
      return find(props.users, user => +user.id === +id) || { attributes: { name: '' } }
    }

    const pickVendibleRecipient = userId => {
      return find(vendibleRecipientsAttributes.value, o => +o.userId === +userId) || { ratio: 0 }
    }

    const addVendibleRecipients = incomingVendibleRecipientIds => {
      // NOTE: incomingVendibleRecipientIdsは選択済みのidも含まれているので排他的論理和つかう
      const newVendibleRecipientIds = xor(
        map(vendibleRecipientsAttributes.value, o => +o.userId),
        map(incomingVendibleRecipientIds, Number),
      )

      // NOTE: 割合は新しい人数で等分してリセットする(_destroyを除く)
      const persistentVendibleRecipientIds = chain(vendibleRecipientsAttributes.value)
        .reject('_destroy')
        .map(o => +o.userId)
        .value()
      const ratio = 1 / [...persistentVendibleRecipientIds, ...newVendibleRecipientIds].length

      each(newVendibleRecipientIds, id => {
        vendibleRecipientsAttributes.value.push({ userId: id, ratio })
      })

      // NOTE: 保存済みのvendibleRecipientAttributesに対してもratioを変更しないといけないので
      each(vendibleRecipientsAttributes.value, obj => {
        update(obj, 'ratio', () => ratio)
      })
    }

    const removeVendibleRecipient = user => {
      const index = findIndex(vendibleRecipientsAttributes.value, o => +o.userId === +user.id)
      const deleteTargetVendibleRecipient = vendibleRecipientsAttributes.value.splice(index, 1)[0]
      const persistentVendibleRecipientSize = chain(vendibleRecipientsAttributes.value).reject('_destroy').value().length

      let ratio = 0
      // NOTE: 割合は残った人数で等分してリセットする
      if (!isEmpty(vendibleRecipientsAttributes.value)) ratio = 1 / persistentVendibleRecipientSize

      each(vendibleRecipientsAttributes.value, obj => {
        update(obj, 'ratio', () => ratio)
      })

      // NOTE: 保存済みのもの対して削除であれば、_destroy:trueを付与して戻す
      if (deleteTargetVendibleRecipient?.id) {
        vendibleRecipientsAttributes.value.push({ ...deleteTargetVendibleRecipient, _destroy: true })
      }
    }

    const changeVendibleRecipientRatio = (percent, user) => {
      const newRatio = Number(percent) / 100
      const index = findIndex(
        vendibleRecipientsAttributes.value,
        o => +o.userId === +user.id,
      )
      update(vendibleRecipientsAttributes.value[index], 'ratio', () => newRatio)
    }

    return {
      // data
      vendibleRecipientsAttributes,
      onUserRatios,

      // computed
      userItems,
      badgeColor,

      // methods
      pickUser,
      pickVendibleRecipient,
      addVendibleRecipients,
      removeVendibleRecipient,
      changeVendibleRecipientRatio,

      icons: {
        mdiHeartOutline,
        mdiCheck,
        mdiAccountCircleOutline,
      },
    }
  },
}
</script>
