<template>
  <div>
    <!-- NOTE: 注文時間 start -->
    <v-card-subtitle
      v-if="orderedAt"
      class="ma-0 pa-0 pb-1 pl-1 d-flex align-center"
    >
      <v-icon
        left
        small
      >
        {{ icons.mdiClockOutline, }}
      </v-icon>
      {{ hhmm(orderedAt) }}
    </v-card-subtitle>
    <!-- NOTE: 注文時間 end -->

    <v-card
      class="pa-4"
      outlined
      :class="value._destroy ? '_destroy-color' : ''"
    >
      <!-- NOTE: 削除ボタン start -->
      <v-btn
        v-if="removable"
        icon
        fab
        :ripple="false"
        class="remove-btn"
        @click="clickRemoveBtn"
      >
        <v-icon>
          {{ icons.mdiCloseBox }}
        </v-icon>
      </v-btn>
      <!-- NOTE: 削除ボタン end -->

      <!-- eslint-disable vue/v-on-event-hyphenation -->
      <vendible
        :vendibles="vendibles"
        :is-non-exist-vendible="isNonExistVendible"
        :vendible-id="vendibleId"
        :vendible-type="vendibleType"
        :vendible-name="vendibleName"
        :selling-price="sellingPrice"
        :is-edit="!!value.id"
        @update:vendibleId="vendibleId = $event"
        @update:vendibleType="vendibleType = $event"
        @update:vendibleName="vendibleName = $event"
        @update:isNonExistVendible="isNonExistVendible = $event"
        @update:sellingPrice="sellingPrice = $event"
        @toggle-is-non-exist="vendingHistorySize = 1"
      />

      <!-- NOTE: 合計 start -->
      <div v-if="sizable">
        <div class="d-flex align-center flex-wrap mt-1 vendible-size-number-counter-container">
          <!-- NOTE: 個数変更 start -->
          <number-counter
            :number="vendingHistorySize"
            :min="1"
            @change="vendingHistorySize = $event"
          >
            <template #prepend-number>
              <span>×</span>
            </template>
          </number-counter>
          <!-- NOTE: 個数変更 end -->

          <v-spacer />

          <p
            class="ma-0 pa-0 pr-1 text-base font-weight-semibold text-right"
          >
            ¥{{ total.toLocaleString() }}
          </p>
        </div>

        <v-divider />
      </div>
      <!-- NOTE: 合計 end -->

      <!-- NOTE: TAX（税サ）の取り方 start -->
      <div class="pt-1 tax-kind-chip-group-container">
        <v-chip-group
          v-model="taxCharge"
          mandatory
          active-class="v-chip-light-bg primary--text tax-kind-chip-group"
        >
          <v-chip
            v-for="(taxName, taxValue) in taxCharges"
            :key="taxValue"
            :value="taxValue"
            small
            outlined
            filter
            :class="`tax-kind-chip-${taxValue}`"
          >
            {{ taxName }}
          </v-chip>
        </v-chip-group>
      </div>
      <!-- NOTE: TAX（税サ）の取り方 end -->

      <!-- NOTE: 商品受領者 start -->
      <div v-if="users.length > 0 && vendibleType !== 'Course'">
        <div
          v-if="isMultipleVendibleRecipients && vendibleType !== 'Nomination'"
          class="d-flex align-center"
        >
          <multiple-vendible-recipients
            v-model="vendibleRecipientsAttributes"
            :users="vendibleRecipientUsers"
            class="w-full"
            @update:value="vendibleRecipientsAttributes = $event"
          />
          <v-btn
            icon
            fab
            :ripple="false"
            :disabled="!!value.id"
            class="receiver-select-mode-toggle-btn"
            @click="toggleIsMultipleVendibleRecipients"
          >
            <v-icon>
              {{ icons.mdiHeartMultipleOutline }}
            </v-icon>
          </v-btn>
        </div>

        <div
          v-else
          class="d-flex align-center"
        >
          <single-vendible-recipient
            v-model="vendibleRecipientsAttributes"
            :users="vendibleRecipientUsers"
            class="w-full"
            :error="vendibleType === 'Nomination' && (vendibleRecipientsAttributes.length < 1 || vendibleRecipientsAttributes.some(o => !o.userId))"
            @update:value="vendibleRecipientsAttributes = $event"
          />
          <v-btn
            icon
            fab
            :ripple="false"
            :disabled="!!value.id"
            class="receiver-select-mode-toggle-btn"
            @click="toggleIsMultipleVendibleRecipients"
          >
            <v-icon>
              {{ icons.mdiHeartOutline }}
            </v-icon>
          </v-btn>
        </div>
      </div>
      <!-- NOTE: 商品受領者 end -->

      <!-- eslint-disable vue/v-on-event-hyphenation -->
      <vending-contributions
        v-if="users.length > 0 && !hideVendingContributors"
        v-model="vendingContributionsAttributes"
        :users="users"
        @update:value="vendingContributionsAttributes = $event"
        @is-manual="isArbitraryVendingContribution = $event"
      />

      <slot />
    </v-card>

    <!-- NOTE: 単価は編集時にいじれないので、プラスの値段がマイナスになることはない == 前金はfalseからい変えられない -->
    <v-switch
      v-if="(orderedAt || isPaidInAdvance) && sellingPrice > 0"
      v-model="isPaidInAdvance"
      label="前金"
      color="warning"
      dense
    />

    <slot name="outside" />
  </div>
</template>

<script>
import {
  ref,
  computed,
  watch,
  inject,
} from '@vue/composition-api'
import { useVModel, useVModels } from '@vueuse/core'
import { flatMap, groupBy } from 'lodash'
import {
  mdiCloseBox,
  mdiClockOutline,
  mdiHeartMultipleOutline,
  mdiHeartOutline,
} from '@mdi/js'
import NumberCounter from '@/views/components/util/NumberCounter.vue'
import useDateFormat from '@/views/composable/useDateFormat'
import Vendible from '@/views/components/table/OrderCard/Vendible.vue'
import MultipleVendibleRecipients from '@/views/components/table/OrderCard/MultipleVendibleRecipients.vue'
import SingleVendibleRecipient from '@/views/components/table/OrderCard/SingleVendibleRecipient.vue'
import VendingContributions from '@/views/components/table/OrderCard/VendingContributions.vue'

export default {
  components: {
    NumberCounter,
    Vendible,
    MultipleVendibleRecipients,
    SingleVendibleRecipient,
    VendingContributions,
  },
  props: {
    value: {
      type: Object,
      default: () => {},
      required: true,
    },
    size: {
      type: Number,
      default: 1,
    },
    vendibles: {
      type: Array,
      default: () => [],
      required: true,
    },
    users: {
      type: Array,
      default: () => [],
    },
    sizable: {
      type: Boolean,
      default: true,
    },
    orderedAt: {
      type: String,
      required: false,
      default: null,
    },
    removable: {
      type: Boolean,
      default: true,
    },
    hideVendingContributors: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const { hhmm } = useDateFormat()
    // eslint-disable-next-line no-underscore-dangle
    const {
      vendibleId,
      vendibleType,
      isNonExistVendible,
      vendibleName,
      sellingPrice,
      taxCharge,
      vendingContributionsAttributes,
      vendibleRecipientsAttributes,
      _destroy,
      isPaidInAdvance,
    } = useVModels(props.value, emit)
    const vendingHistorySize = useVModel(props, 'size')
    const isArbitraryVendingContribution = useVModel(props, 'isArbitraryVendingContribution')
    const isMultipleVendibleRecipients = ref(vendibleRecipientsAttributes.value?.length > 0)

    const usersWithTimecard = inject('usersWithTimecard', [])

    const total = computed(() => {
      return sellingPrice.value * props.size
    })

    const taxCharges = computed(() => {
      if (total.value < 0) return { normal: '通常' }

      // NOTE: includedは販売貢献人がその税金分を負担するため、vendingContributionsAttributesがなければ表示しない
      return vendingContributionsAttributes.value.length > 0
        ? { normal: '通常', included: '込み', cut: 'カット' }
        : { normal: '通常', cut: 'カット' }
    })

    const vendibleRecipientUsers = computed(() => {
      if (vendibleType.value === 'Course') return []

      const roleTargetsByType = {
        Nomination: ['cast'],
        Item: ['cast', 'waiter'],
      }[vendibleType.value]

      const usersGrouped = groupBy(props.users, 'attributes.role')
      const workingUsersGrouped = groupBy(usersWithTimecard.value, 'attributes.role')

      return flatMap(roleTargetsByType, role => {
        return workingUsersGrouped[role] && workingUsersGrouped[role].length > 0
          ? workingUsersGrouped[role]
          : usersGrouped[role]
      })
    })

    const toggleIsMultipleVendibleRecipients = () => {
      if (vendibleType.value === 'Nomination') return

      isMultipleVendibleRecipients.value = !isMultipleVendibleRecipients.value
      vendibleRecipientsAttributes.value = []
    }

    const clickRemoveBtn = () => {
      if (!props.value.id) {
        emit('remove')

        return
      }

      if (_destroy.value) {
        _destroy.value = false
      } else {
        emit('remove')
      }
    }

    // NOTE: マイナスの値段の商品（クーポンとか）の場合
    watch(() => total.value, (newTotal, _prevTotal) => {
      if (newTotal < 0) taxCharge.value = 'normal'
    })

    // NOTE: セットの場合は受領を空にする
    // 編集時は商品を変更することはできない。新規作成のみ想定するので空の配列で十分(_destroyとか考慮しない)
    watch(() => vendibleType.value, (newVendibleType, _prevVendibleType) => {
      if (newVendibleType === 'Course') vendibleRecipientsAttributes.value = []
    })

    return {
      // data
      sellingPrice,
      taxCharge,
      vendibleId,
      vendibleType,
      vendibleName,
      isNonExistVendible,
      vendingContributionsAttributes,
      vendibleRecipientsAttributes,
      isArbitraryVendingContribution,
      vendingHistorySize,
      isMultipleVendibleRecipients,
      isPaidInAdvance,

      // computed
      taxCharges,
      total,
      vendibleRecipientUsers,

      // methods
      hhmm,
      clickRemoveBtn,
      toggleIsMultipleVendibleRecipients,

      icons: {
        mdiCloseBox,
        mdiClockOutline,
        mdiHeartMultipleOutline,
        mdiHeartOutline,
      },
    }
  },
}
</script>

<style lang="scss" scoped>
.remove-btn {
  position: absolute;
  top: -8px;
  right: -8px;
}
._destroy-color {
  opacity: 0.5;
  filter: hue-rotate(30deg);
}
.v-select-list .v-list-item--link {
  height: auto;
}
</style>
